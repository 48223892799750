import { Spin } from 'antd';
import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import withAdminLayout from '../../layout/withAdminLayout';

// Dashboard
const Dashboard = lazy(() => import('../../container/pages/Dashboard'));

// Settings
const Profile = lazy(() => import('../../TeesNMore/Settings/EditProfile'))
const ChangePassword = lazy(() => import('../../TeesNMore/Settings/ChangePassword'))
const SiteSettings = lazy(() => import('../../TeesNMore/Settings/SiteSettings'))

// Catelouge -> Products
const AllListing = lazy(() => import('../../TeesNMore/Listing/AllListing'));
const ListingDetails = lazy(() => import('../../TeesNMore/Listing/ListingDetails'));

// Catelouge -> Categories
const MainCategories = lazy(() => import('../../TeesNMore/Categories/MainCategories/MainCategories'));
const SubCategories = lazy(() => import('../../TeesNMore/Categories/SubCategories/SubCategories'));

// Suppliers
const AllSuppliers = lazy(() => import('../../TeesNMore/Suppliers/AllSuppliers/AllSuppliers'));
const SupplierInfo = lazy(() => import('../../TeesNMore/Suppliers/SupplierInfo/SupplierInfo'));

// CMS -> MenuManagement
const MenuManagement = lazy(() => import('../../TeesNMore/CMS/MenuManagement/MenuManagement'))

// CMS -> BannerManagement
const BannerManagement = lazy(() => import('../../TeesNMore/CMS/BannerManagement/BannerManagement'))
const CreateBannerManagement = lazy(() => import('../../TeesNMore/CMS/BannerManagement/CreateBannerManagement'))

// CMS -> ManageHome
const ManageHome = lazy(() => import('../../TeesNMore/CMS/ManageHome/ManageHome'))

// CMS -> ManageAbout
const ManageAbout = lazy(() => import('../../TeesNMore/CMS/ManageAbout/ManageAbout'))

// CMS -> ArtworkRequirement
const ArtworkRequirement = lazy(() => import('../../TeesNMore/CMS/ArtworkRequirement/ArtworkRequirement'))

// CMS -> FAQs Management
const FaqTypes = lazy(() => import('../../TeesNMore/CMS/FAQs/FaqTypes'))
const FaqListing = lazy(() => import('../../TeesNMore/CMS/FAQs/FaqListing'))

// CMS -> ProductPageInfo
const ProductPageInfo = lazy(() => import('../../TeesNMore/CMS/ProductPageInfo/ProductPageInfo'))

// CMS -> CustomizationGuide
const CustomizationGuide = lazy(() => import('../../TeesNMore/CMS/CustomizationGuide/CustomizationGuide'))
const EditCustomizationGuide = lazy(() => import('../../TeesNMore/CMS/CustomizationGuide/EditCustomizationGuide'))

// CMS -> Policies
const TermsConditions = lazy(() => import('../../TeesNMore/CMS/TermsConditions/TermsConditions'))
const CreateTermsConditions = lazy(() => import('../../TeesNMore/CMS/TermsConditions/CreateTermsConditions'))
const PrivacyPolicy = lazy(() => import('../../TeesNMore/CMS/PrivacyPolicy/PrivacyPolicy'))
const ReturnPolicy = lazy(() => import('../../TeesNMore/CMS/ReturnPolicy/ReturnPolicy'))

// Contact Us
const ContactUs = lazy(() => import('../../TeesNMore/CMS/ContactUs/ContactUs'))
const ContactUsDetails = lazy(() => import('../../TeesNMore/CMS/ContactUs/ContactUsDetails'))

const Admin = React.memo(() => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Routes>
        
        {/*Dashboard */}
        <Route path="admin/dashboard" element={<Dashboard />} />

        {/* Settings */}
        <Route path="admin/settings/edit-profile" element={<Profile />}  />
        <Route path="admin/settings/change-password" element={<ChangePassword />}  />
        <Route path="admin/settings/site-settings" element={<SiteSettings />} />

        {/* Categories */}
        <Route path="admin/categories" element={<MainCategories />} />
        <Route path="admin/sub-categories" element={<SubCategories />} />
      
        {/* Products */}
        <Route path="admin/product-management/listing" element={<AllListing />} />
        <Route path="admin/products/details" element={<ListingDetails />} />


        {/* Suppliers */}
        <Route path="admin/supplier-management/listing" element={<AllSuppliers />} />
        <Route path="admin/supplier-management/details/:id" element={<SupplierInfo />} />

        {/* CMS  */}

        {/* CMS - Banner Management*/}
        <Route path="admin/cms/banner-management" element={<BannerManagement />} />
        <Route path="admin/cms/banner-management/create" element={<CreateBannerManagement />} />
        <Route path="admin/cms/banner-management/edit" element={<CreateBannerManagement />} />

        {/* CMS - Menu Management */}
        <Route path="admin/cms/menu-management" element={<MenuManagement />} />

        {/* CMS - Manage About */}
        <Route path="admin/cms/manage-about" element={<ManageAbout />} />

        {/* CMS - Artwork Requirement */}
        <Route path="admin/cms/artwork-requirement" element={<ArtworkRequirement />} />

        {/* CMS - Terms & Conditions */}
        <Route path="admin/cms/terms" element={<TermsConditions />} />
        <Route path="admin/cms/terms/create" element={<CreateTermsConditions />}  />
        <Route path="admin/cms/terms/edit" element={<CreateTermsConditions />}  />

        {/* CMS - Privacy Policy */}
        <Route path="admin/cms/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="admin/cms/privacy-policy/create" element={<CreateTermsConditions />}  />
        <Route path="admin/cms/privacy-policy/edit" element={<CreateTermsConditions />}  />

        {/* CMS - Faqs Policy */}
        <Route path="admin/cms/faqs" element={<FaqTypes />} />
        <Route path="admin/cms/faqs/create" element={<CreateTermsConditions />}  />
        <Route path="admin/cms/faqs/create/:id" element={<CreateTermsConditions />} />
        <Route path="admin/cms/faqs/edit" element={<CreateTermsConditions />}  />
        <Route path="admin/cms/faqs/edit/:id" element={<CreateTermsConditions />}  />
        <Route path="admin/cms/faqs-listing/:id" element={<FaqListing />} />

        {/* CMS - Return Policy */}
        <Route path="admin/cms/return-policy" element={<ReturnPolicy />} />
        <Route path="admin/cms/return-policy/create" element={<CreateTermsConditions />}  />
        <Route path="admin/cms/return-policy/edit" element={<CreateTermsConditions />}  />

        {/* CMS - Manage Home */}
        <Route path="admin/cms/manage-home" element={<ManageHome />} />

        {/* CMS - Customization Guide */}
        <Route path="admin/cms/customization-guide" element={<CustomizationGuide />} />
        <Route path="admin/cms/customization-guide/edit" element={<EditCustomizationGuide />} />

        {/* CMS - Product Page Info */}
        <Route path="admin/cms/product-page-info" element={<ProductPageInfo />} />

        {/* CMS - Contact Us */}
        <Route path="admin/cms/contact-us" element={<ContactUs />} />
        <Route path="admin/cms/contact-us/details" element={<ContactUsDetails />} />

       {/* Not Found */}
       <Route path="*" element={<Navigate to="/admin/dashboard" />} />
       
      </Routes>
    </Suspense>
  );
});

export default withAdminLayout(Admin);
